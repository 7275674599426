













































import {Component, Prop, Vue} from 'vue-property-decorator';
import ObjectiveDetailed from '@/components/board/objective/ObjectiveDetailed.vue';
import {
  Objective as ObjectiveType,
  ObjectiveStatus
} from '@/typescript/types';
import DateTimeInput from '@/components/board/DateTimeInput.vue';
import UserAvatar from "@/components/user/UserAvatar.vue";
import KeyResult from "@/components/board/KeyResult.vue";
import Template from "@/components/templates/Template.vue";

import { isPast} from "date-fns";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import contextMenu from 'vue-context-menu';
import RoomTutorial from '@/components/auth/demo/RoomTutorial.vue';

@Component({
  components: {
    Template,
    KeyResult,
    UserAvatar,
    contextMenu,
    DateTimeInput,
    ObjectiveDetailed,
    ConfettiCanon
  },
})

export default class FakeObjective extends Vue {

  isStatusChangedToCompleted: boolean = false;
  allowSwitchStatus: boolean = true;
  displayFakeObjective: boolean = true;
  fakeObjectiveChecked: boolean = false;

  @Prop({default: undefined, required: false})
  currentDueDate!: Date;

  @Prop({default: undefined, required: false})
  objective!: ObjectiveType;

  @Prop({default: undefined, required: false})
  index!: Number;


  

  get status(): string {
    let objective = this.objective;

    if (!objective) {
      return 'Not started'
    }

    if (objective.status === ObjectiveStatus.InProgress) {
      return 'In progress'
    }

    if (objective.status === ObjectiveStatus.NotStarted) {
      return 'Not started'
    }

    if (objective.status === ObjectiveStatus.Pending) {
      return 'Pending'
    }

    if (objective.status === ObjectiveStatus.Completed) {
      return 'Completed'
    }

    if (objective.status === ObjectiveStatus.Cancelled) {
      return 'Cancelled'
    }

    return 'Not started'
  }

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get isCompleted() {
    return this.status === 'Completed';
  }

  get isPast() {
    return isPast(new Date(this.objective.due_date));
  }

  get isInGridView() {
    if(this.$store.state.dashboard_view === 'grid' && !this.$store.state.board) {
      return true;
    } else {
      return false;
    }
  }

  get showOwner() {
    return this.$store.state.objective_data_members;
  }

  get showStatus() {
    return this.$store.state.objective_data_status;
  }

  get showStartDate() {
    return this.$store.state.objective_data_start_date;
  }

  get showDueDate() {
    return this.$store.state.objective_data_due_date;
  }

  get expansionActivityId() {
    return this.$store.state.expansion_activity_id;
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      // this plays when an initiative is marked as completed
      let audio = new Audio(require('@/assets/audio/initiative-check.mp3'));
      audio.volume = 0.02;
      audio.play();
    }
  }
 

  // handleFakeObjectiveCheck() {     
  //   this.fakeObjectiveChecked = true;
  //   setTimeout(() => {
  //     setTimeout(() => {    
  //       this.$store.commit('set_onboarding_step', 8);
  //       this.displayFakeObjective = false;
  //     }, 1000);  
  //     this.openComplete();
  //   }, 1000);
    
  // }

  openComplete() {
    this.$buefy.modal.open({
      component: RoomTutorial,
      width: '800px',
      parent: this,
      customClass: 'room_onboarding_modal',
      canCancel: false,
    });
  }


  



}
